import axios from '@/common/axios'
import qs from 'qs'

export function addHazardousChemicals (data) {
  return axios({
    method: 'post',
    url: '/quality/hazardousChemicals/add',
    data: qs.stringify(data)
  })
}

export function deleteHazardousChemicals (id) {
  return axios({
    method: 'delete',
    url: '/quality/hazardousChemicals/delete/' + id
  })
}

export function updateHazardousChemicals (data) {
  return axios({
    method: 'put',
    url: '/quality/hazardousChemicals/update',
    data: qs.stringify(data)
  })
}

export function selectHazardousChemicalsInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/hazardousChemicals/info/' + id
  })
}

export function selectHazardousChemicalsList (query) {
  return axios({
    method: 'get',
    url: '/quality/hazardousChemicals/list',
    params: query
  })
}

export function grantHazardousChemicals (data) {
  return axios({
    method: 'put',
    url: '/quality/hazardousChemicals/updateIssue',
    data: qs.stringify(data)
  })
}

export function superviseHazardousChemicals (data) {
  return axios({
    method: 'put',
    url: '/quality/hazardousChemicals/updateRequisition',
    data: qs.stringify(data)
  })
}
