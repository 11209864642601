<template>
  <div id="hazardousChemicals">
    <el-dialog
      :title="hazardousChemicalsFormTitle"
      width="1200px"
      :visible.sync="hazardousChemicalsDialogVisible"
      :close-on-click-modal="false"
      @close="hazardousChemicalsDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="hazardousChemicalsFormRef"
        :model="hazardousChemicalsForm"
        :rules="hazardousChemicalsFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="hazardousChemicalsForm.name"
                placeholder="请输入名称"
                clearable
                :disabled="hazardousChemicalsFormTitle !== '新增危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '修改危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '危险化学品领、发记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格" prop="spec">
              <el-input
                v-model="hazardousChemicalsForm.spec"
                placeholder="请输入规格"
                clearable
                :disabled="hazardousChemicalsFormTitle !== '新增危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '修改危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '危险化学品领、发记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input
                v-model="hazardousChemicalsForm.quantity"
                placeholder="请输入数量"
                clearable
                :disabled="hazardousChemicalsFormTitle !== '新增危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '修改危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '危险化学品领、发记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input
                v-model="hazardousChemicalsForm.batchNo"
                placeholder="请输入批号"
                clearable
                :disabled="hazardousChemicalsFormTitle !== '新增危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '修改危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '危险化学品领、发记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="有效期" prop="validPeriod">
              <el-input
                v-model="hazardousChemicalsForm.validPeriod"
                placeholder="请输入有效期"
                clearable
                :disabled="hazardousChemicalsFormTitle !== '新增危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '修改危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '危险化学品领、发记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产厂家" prop="manufacturer">
              <el-input
                v-model="hazardousChemicalsForm.manufacturer"
                placeholder="请输入生产厂家"
                clearable
                :disabled="hazardousChemicalsFormTitle !== '新增危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '修改危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '危险化学品领、发记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="储存地点" prop="location">
              <el-input
                v-model="hazardousChemicalsForm.location"
                placeholder="请输入储存地点"
                clearable
                :disabled="hazardousChemicalsFormTitle !== '新增危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '修改危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '危险化学品领、发记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="管理员1" prop="manager1">
              <el-input
                v-model="hazardousChemicalsForm.manager1"
                placeholder="请输入管理员1"
                clearable
                :disabled="hazardousChemicalsFormTitle !== '新增危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '修改危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '危险化学品领、发记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="管理人员2" prop="manager2">
              <el-input
                v-model="hazardousChemicalsForm.manager2"
                placeholder="请输入管理人员2"
                clearable
                :disabled="hazardousChemicalsFormTitle !== '新增危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '修改危险化学品领、发记录'
                  && hazardousChemicalsFormTitle !== '危险化学品领、发记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar v-if="hazardousChemicalsFormTitle === '发放'">
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="issueList"
            :edit-config="{trigger: 'click', mode: 'cell', activeMethod: activeRowMethod}"
          >
            <vxe-table-column
              field="requisitionDate"
              title="领用日期"
              width="150"
              :edit-render="{name: '$input', props: {type: 'date', clearable: true}}"
            />
            <vxe-table-column
              field="dept"
              title="领用部门"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="recipient"
              title="领用人员"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="receiptAmount"
              title="领用量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remainingQuantity"
              title="结存量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="purpose"
              title="用途"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="issuer"
              title="发放人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="supervisor"
              title="监督人"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              v-if="hazardousChemicalsFormTitle === '发放'
                || hazardousChemicalsFormTitle === '监督' "
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button
                    v-if="hazardousChemicalsFormTitle === '发放'
                      && row.status === 1"
                    @click="removeRowEvent(row)"
                  >
                    删除
                  </vxe-button>
                  <vxe-button
                    v-if="hazardousChemicalsFormTitle === '监督'
                      && row.status === 1"
                    @click="superviseEvent(row)"
                  >
                    监督
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="hazardousChemicalsDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="hazardousChemicalsFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="hazardousChemicalsFormTitle === '危险化学品领、发记录详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="名称">
        <el-input v-model="searchForm.name" placeholder="请输入名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CHEMICALS_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="hazardousChemicalsPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="spec" label="规格" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="validPeriod" label="有效期" />
      <el-table-column prop="manufacturer" label="生产厂家" />
      <el-table-column prop="location" label="储存地点" />
      <el-table-column prop="manager1" label="管理员1" />
      <el-table-column prop="manager2" label="管理人员2" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CHEMICALS_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CHEMICALS_UPDATE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['CHEMICALS_GRANT'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleGrant(scope.$index, scope.row)"
          >
            发放
          </el-button>
          <el-button
            v-if="checkPermission(['CHEMICALS_SUPERVISE'])"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleSupervise(scope.$index, scope.row)"
          >
            监督
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="hazardousChemicalsPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addHazardousChemicals,
  deleteHazardousChemicals,
  updateHazardousChemicals,
  selectHazardousChemicalsInfo,
  selectHazardousChemicalsList,
  grantHazardousChemicals,
  superviseHazardousChemicals
} from '@/api/quality/hazardousChemicals'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      hazardousChemicalsDialogVisible: false,
      hazardousChemicalsFormTitle: '',
      hazardousChemicalsForm: {
        id: '',
        name: '',
        spec: '',
        quantity: '',
        batchNo: '',
        validPeriod: '',
        manufacturer: '',
        location: '',
        manager1: '',
        manager2: ''
      },
      hazardousChemicalsFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      hazardousChemicalsPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      issueList: []
    }
  },
  created () {
    selectHazardousChemicalsList(this.searchForm).then(res => {
      this.hazardousChemicalsPage = res
    })
  },
  methods: {
    hazardousChemicalsDialogClose () {
      this.$refs.hazardousChemicalsFormRef.resetFields()
      this.issueList = []
    },
    hazardousChemicalsFormSubmit () {
      if (this.hazardousChemicalsFormTitle === '危险化学品领、发记录详情') {
        this.hazardousChemicalsDialogVisible = false
        return
      }
      this.$refs.hazardousChemicalsFormRef.validate(async valid => {
        if (valid) {
          if (this.hazardousChemicalsFormTitle === '新增危险化学品领、发记录') {
            this.hazardousChemicalsForm.id = ''
            await addHazardousChemicals(this.hazardousChemicalsForm)
          } else if (this.hazardousChemicalsFormTitle === '修改危险化学品领、发记录') {
            await updateHazardousChemicals(this.hazardousChemicalsForm)
          } else if (this.hazardousChemicalsFormTitle === '发放') {
            await grantHazardousChemicals({
              id: this.hazardousChemicalsForm.id,
              issueJson: JSON.stringify(this.$refs.xTable.getTableData().tableData)
            })
          }
          this.hazardousChemicalsPage = await selectHazardousChemicalsList(this.searchForm)
          this.hazardousChemicalsDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.hazardousChemicalsFormTitle = '新增危险化学品领、发记录'
      this.hazardousChemicalsDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteHazardousChemicals(row.id)
        if (this.hazardousChemicalsPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.hazardousChemicalsPage = await selectHazardousChemicalsList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.hazardousChemicalsFormTitle = '修改危险化学品领、发记录'
      this.hazardousChemicalsDialogVisible = true
      this.selectHazardousChemicalsInfoById(row.id)
    },
    handleGrant (index, row) {
      this.hazardousChemicalsFormTitle = '发放'
      this.hazardousChemicalsDialogVisible = true
      this.selectHazardousChemicalsInfoById(row.id)
    },
    handleSupervise (index, row) {
      this.hazardousChemicalsFormTitle = '监督'
      this.hazardousChemicalsDialogVisible = true
      this.selectHazardousChemicalsInfoById(row.id)
    },
    handleInfo (index, row) {
      this.hazardousChemicalsFormTitle = '危险化学品领、发记录详情'
      this.hazardousChemicalsDialogVisible = true
      this.selectHazardousChemicalsInfoById(row.id)
    },
    selectHazardousChemicalsInfoById (id) {
      selectHazardousChemicalsInfo(id).then(res => {
        this.hazardousChemicalsForm.id = res.id
        this.hazardousChemicalsForm.name = res.name
        this.hazardousChemicalsForm.spec = res.spec
        this.hazardousChemicalsForm.quantity = res.quantity
        this.hazardousChemicalsForm.batchNo = res.batchNo
        this.hazardousChemicalsForm.validPeriod = res.validPeriod
        this.hazardousChemicalsForm.manufacturer = res.manufacturer
        this.hazardousChemicalsForm.location = res.location
        this.hazardousChemicalsForm.manager1 = res.manager1
        this.hazardousChemicalsForm.manager2 = res.manager2
        this.issueList = res.issueList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectHazardousChemicalsList(this.searchForm).then(res => {
        this.hazardousChemicalsPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectHazardousChemicalsList(this.searchForm).then(res => {
        this.hazardousChemicalsPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectHazardousChemicalsList(this.searchForm).then(res => {
        this.hazardousChemicalsPage = res
      })
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({ status: 1 }, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    },
    superviseEvent (row) {
      superviseHazardousChemicals({ issueId: row.id }).then(res => {
        this.selectHazardousChemicalsInfoById(this.hazardousChemicalsForm.id)
      })
    },
    activeRowMethod ({ row, rowIndex }) {
      if (this.hazardousChemicalsFormTitle === '发放') {
        return row.status === 1
      }
      return false
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `危险化学品领、发记录${'-'+this.hazardousChemicalsForm.name}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
